<template>
  <div class="content">
    <h1 v-if="fulfilment">Order Details for {{ fulfilment.Id }}</h1>
    <div v-if="fulfilment">

      <div class="tile" v-if="fulfilment.SubhouseId || fulfilment.SubhouseStartIssue" style="float:right">
        <div id="CustomerService" class="box">
          <h5>Subhouse Info</h5>
          <table class="table is-size-8" style="width:auto">
            <tr>
              <td style="max-width: 200px"><b>Account Id</b></td>
              <td>{{ fulfilment.SubhouseId }}</td>
            </tr>
            <!-- <tr>
              <td><b>File Link name</b></td>
              <td>{{ fulfilment.SubhouseLink }}</td>
            </tr> -->
            <tr>
              <td><b>Subhouse Start Issue</b></td>
              <td>{{ fulfilment.SubhouseStartIssue }}</td>
            </tr>
          </table>
        </div>
      </div>
      

      <div class="buttons">
        <button class="button is-primary" v-on:click="editContactDetails()">
          Edit Contact Addresses
        </button>
        <button
          class="button is-primary"
          v-if="hasFullAccess()"
          v-on:click="showEditGeneralDetailsDialog = true"
        >
          Edit General Details
        </button>
        <button
          class="button is-primary"
          v-if="hasFullAccess() && canResendFulfilment()"
          v-on:click="unassignConfirmBulk"
        >
          Mark As Unfulfilled
        </button>
        <button class="button is-primary" v-on:click="showLogDialog = true">
          Show Logs
        </button>
        <button class="button" v-on:click="showFirstIssueDialog = true">
          First Issue Email Template
        </button>

        <a class="button" target="_blank" :href="getOrderTrackerUrl">
          Order Track Site
        </a>
      </div>

      

      <div class="tile" style="clear:right">
        <div class="tile is-parent">
          <div class="tile is-child box">
            <order-general-info :fulfilment="fulfilment"></order-general-info>
          </div>
        </div>
        <div class="tile is-4 is-parent">
          <div class="tile is-child box">
            <order-fulfilment-info
              :fulfilment="fulfilment"
            ></order-fulfilment-info>
          </div>
        </div>
        <div class="tile is-4 is-parent">
          <div class="tile is-child box">
            <order-finanical-info
              :fulfilment="fulfilment"
            ></order-finanical-info>
          </div>
        </div>
      </div>
    </div>
    <div v-if="fulfilment">
      <contact-groups-table-for-fulfilment
        :fulfilment-id="fulfilment.Id"
      ></contact-groups-table-for-fulfilment>
    </div>
    <br />
    <br />

    <div>
      <h2>Full Data</h2>
      <json-tree-display
        class="is-size-6"
        v-if="fulfilment"
        :model.sync="fulfilment.Data"
      ></json-tree-display>
    </div>

    <datalist id="refulfilreasons">
      <option value="Missing Country"></option>
      <option value="Address to long"></option>
    </datalist>

    <div class="modal is-active" v-if="showDialogue">
      <div class="modal-background"></div>
      <div v-if="stage == 0" class="modal-card">
        <header class="modal-card-head">
          <span class="modal-card-title">{{ DialogueTitle }}</span>
          <button
            class="delete"
            aria-label="close"
            v-on:click="closeDialog"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <h5>Delivery</h5>
              <fulfilment-address-edit
                :model="fulfilment.Data.recipient_contacts"
                prefix="recipient_contact"
              ></fulfilment-address-edit>
            </div>
            <div class="column">
              <h5>Billing &amp; Primary</h5>
              <fulfilment-address-edit
                :model="fulfilment.Data.billing_contact"
                prefix="billing_contact"
              ></fulfilment-address-edit>
            </div>
          </div>

          <br />
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" v-on:click="updateDetails">
            Save changes
          </button>
          <button class="button" v-on:click="closeDialog">Cancel</button>
        </footer>
      </div>
      <div v-else-if="stage == 1" class="modal-card">
        <header class="modal-card-head">
          <span class="modal-card-title">Reply to customer</span>
        </header>
        <section class="modal-card-body">
          <!-- <br />
          Has Been Fulfilled: {{hasBeenFulfilled}}
          <br />
          Is Cancelled/Test: {{hasBeenCancelled}}
          <br />-->
          <div v-if="hasBeenFulfilled">
            <p>
              <b>1.</b> Add the below customer service to the ‘to’ of your reply
              to the customer in Freskdesk, ensure the customer is still
              included.
            </p>

            <contact-table
              :contact="this.fulfilment.CustomerServiceContacts[0]"
              :show-copy="true"
            ></contact-table>

            <p>
              <b>2.</b>&nbsp;
              <span
                >Copy the below text in to your email reply to the customer in
                Freshdesk. Along with anything else necessary to personalise/
                make relevant to the customer's email then send.</span
              >
            </p>

            <div class="freshdeskcopy">
              <div class="copy">
                <span class="title is-6">Email Template to copy</span>
                <button
                  class="button is-small"
                  v-on:click="copyToClipboard('freshDeskCopyAddressChanges')"
                >
                  Copy
                </button>
              </div>
              <div class="copybody">
                <div id="freshDeskCopyAddressChanges">
                  <p>Hi {{ customerReplyName }}</p>
                  <p>Thank you for your email.</p>
                  <p>
                    {{ this.fulfilment.CustomerServiceContacts[0].Email }},
                    please note that the field(s) below in <b>bold</b> need to
                    be updated. Please can these be updated in your system?
                  </p>

                  <fulfilment-address-changes
                    :before-model="fulfilmentPreChanges"
                    :after-model="formModelRecipient"
                    address-prefix="recipient_contact"
                  ></fulfilment-address-changes>

                  <fulfilment-address-changes
                    :before-model="fulfilmentPreChanges"
                    :after-model="formModelBilling"
                    address-prefix="billing_contact"
                  ></fulfilment-address-changes>

                  <p>
                    For the publisher's reference, we can confirm the following
                    order details:
                  </p>

                  <fulfilment-info-for-bureau
                    :fulfilment="fulfilment"
                    class="table is-size-8"
                    style="width: auto"
                  ></fulfilment-info-for-bureau>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p>
              <b>1.</b>&nbsp;
              <span
                >Copy the below text in to your email reply to the customer in
                Freshdesk. Along with anything else necessary to personalise/
                make relevant to the customer's email then send.</span
              >
            </p>

            <div class="freshdeskcopy">
              <div class="copy">
                <span class="title is-6">Email Template to copy</span>
                <button
                  class="button is-small"
                  v-on:click="copyToClipboard('freshDeskCopyAddressChanges')"
                >
                  Copy
                </button>
              </div>
              <div class="copybody">
                <div id="freshDeskCopyAddressChanges">
                  <p>Hi {{ customerReplyName }}</p>
                  <p>Thank you for your email.</p>
                  <p>The below changes have now been completed.</p>
                  <p>
                    Any field in
                    <b>bold</b> has been updated.
                  </p>

                  <fulfilment-address-changes
                    :before-model="fulfilmentPreChanges"
                    :after-model="formModelRecipient"
                    address-prefix="recipient_contact"
                  ></fulfilment-address-changes>

                  <fulfilment-address-changes
                    :before-model="fulfilmentPreChanges"
                    :after-model="formModelBilling"
                    address-prefix="billing_contact"
                  ></fulfilment-address-changes>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer class="modal-card-foot">
          <button class="button is-success" v-on:click="completeDetailsUpdate">
            Finish
          </button>
        </footer>
      </div>
    </div>

    <fulfilment-first-issue-email-template-dialog
      v-if="showFirstIssueDialog"
      :fulfilment="fulfilment"
      v-on:close="showFirstIssueDialog = false"
    ></fulfilment-first-issue-email-template-dialog>

    <fulfilment-log-dialog
      v-if="showLogDialog"
      :fulfilment="fulfilment"
      v-on:close="showLogDialog = false"
    ></fulfilment-log-dialog>
    <order-general-details-dialog
      v-if="showEditGeneralDetailsDialog"
      :fulfilment="fulfilment"
      v-on:close="showEditGeneralDetailsDialog = false"
    >
    </order-general-details-dialog>
  </div>
</template>

<script>
// import recipientContactSchema from "../recipientContactSchema.js";
// import billingContactSchema from "../billingContactSchema.js";

import { baseMixin } from "../../mixins/baseMixin.js";

export default {
  data: function () {
    return {
      APIUrl: process.env.VUE_APP_APIURL,
      fulfilment: null,
      fulfilmentPreChanges: null,
      IsGift: false,
      DialogueTitle: null,
      formModelRecipient: null,
      formModelBilling: null,
      showLogDialog: false,
      showFirstIssueDialog: false,
      showEditGeneralDetailsDialog: false,
      stage: 0,
      showDialogue: false,
    };
  },
  mixins: [baseMixin],

  components: {
    contactTable: () => import("../components/molecules/contactTable.vue"),
    fulfilmentAddressEdit: () =>
      import("../components/molecules/fulfilmentAddressEdit.vue"),
    fulfilmentAddressChanges: () =>
      import("../components/molecules/fulfilmentAddressChanges.vue"),
    fulfilmentInfoForBureau: () =>
      import("../components/molecules/fulfilmentInfoForBureau.vue"),
    fulfilmentLogDialog: () =>
      import("../components/organisms/fulfilmentLogDialog.vue"),
    fulfilmentFirstIssueEmailTemplateDialog: () =>
      import(
        "../components/organisms/fulfilmentFirstIssueEmailTemplateDialog.vue"
      ),
    jsonTreeDisplay: () => import("../components/atom/jsonTreeDisplay.vue"),
    orderFulfilmentInfo: () =>
      import("../components/organisms/orderFulfilmentInfo.vue"),
    orderGeneralInfo: () =>
      import("../components/organisms/orderGeneralInfo.vue"),
    orderFinanicalInfo: () =>
      import("../components/organisms/orderFinanicalInfo.vue"),
    contactGroupsTableForFulfilment: () =>
      import("../components/organisms/contactGroupsTableForFulfilment.vue"),
    orderGeneralDetailsDialog: () =>
      import("../components/organisms/orderGeneralDetailsDialog.vue"),
  },
  created() {
    var url =
      this.APIUrl +
      "/fulfilment/" +
      this.$route.params.id +
      "?includeBatchInfo=true&includeLogs=true&includeCustomerServiceContactInfo=true";
    this.axios.get(url).then((response) => {
      this.fulfilment = response.data;
      this.fulfilment.Data = this.fulfilment.DataObject;
      this.IsGift = Boolean(this.fulfilment.Data.products_info.is_gift);

      this.fulfilmentPreChanges = JSON.parse(JSON.stringify(this.fulfilment));
    });
  },
  methods: {
    editContactDetails() {
      this.showDialogue = true;
      this.stage = 0;
      this.formModelBilling = this.fulfilment.Data.billing_contact;
      this.formModelRecipient = this.fulfilment.Data.recipient_contacts;
      this.DialogueTitle = "Update Contact Addresses";
    },
    updateDetails() {
      //var url = this.APIUrl + "/fulfilment/";
      var that = this;

      //copy billing to primary contact to update as well
      Object.keys(this.fulfilment.Data.billing_contact).map((key) => {
        var section;
        section = key.replace("billing_contact_", "primary_contact_");
        that.fulfilment.Data.primary_contact[section] =
          that.fulfilment.Data.billing_contact[key];
      });

      var data = that.fulfilment.Data;

      var url = this.APIUrl + "/fulfilment/" + that.fulfilment.Id;
      this.axios
        .post(url, data)
        .then((response) => {
          this.$toast.open("Update has successfully completed");
          this.stage = 1;
        })
        .catch(this.catchError);
    },
    completeDetailsUpdate() {
      this.closeDialog();
    },
    Entry(ent) {
      if (ent == null) return null;
      return Object.keys(ent).map((key) => {
        return { Name: key, Value: ent[key] };
      });
    },
    unassignConfirmBulk() {
      var msg =
        "Are you sure you want to mark this as unfulfilled?<br><br>" +
        "<p><b>The fulfilment order will be unassigned from its current batch job and will be then processed the next time its destination is sent</b></p>" +
        "<br>Reason:";

      var title = "Resend fulfilment";

      this.$dialog.prompt({
        title: title,
        message: msg,
        inputAttrs: {
          placeholder: "e.g. reason for this resend",
          maxlength: 1000,
          list: "refulfilreasons",
        },
        type: "is-danger",
        confirmText: "Unassign and Resend",
        onConfirm: (notes) =>
          this.unassignFulfilmentBulk(this.fulfilment.Id, notes),
      });
    },
    unassignFulfilmentBulk(fulfilmentId, notes) {
      var url =
        this.APIUrl +
        "/fulfilment/bulk/unassign?notes=" +
        notes +
        "&id=" +
        fulfilmentId;

      this.axios.put(url).then((response) => {
        //this.updateFulfilmentRecord(response.data);

        this.$toast.open(
          "Unassigned Fulfilment, it will be resent in the next batch job, Reason: " +
            notes
        );
      });
    },
    canResendFulfilment() {
      return this.fulfilment.Status !== 0; //if unassigned, then pointless as it will just set to this status
    },
    closeDialog() {
      this.showDialogue = false;
    },
  },
  computed: {
    sections() {
      if (this.fulfilment === null) return null;
      return Object.keys(this.fulfilment.Data).map((key) => {
        return { Section: key, Value: this.fulfilment.Data[key] };
      });
    },
    hasBeenFulfilled() {
      return (
        this.fulfilment.BatchJobInfo !== null &&
        this.fulfilment.BatchJobInfo.Status === 2
      ); //SENT;
    },
    hasBeenCancelled() {
      return this.fulfilment.Status === 4; //Cancelled/test;
    },
    customerReplyName() {
      return this.fulfilment.Data.primary_contact.primary_contact_first_name;
    },
    getOrderTrackerUrl() {
      return (
        "https://order-tracker.magazine.co.uk/#/" +
        this.fulfilment.Data.meta_info.transaction_ref +
        "/order-progress"
      );
    },
  },
};
</script>
